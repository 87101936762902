<script>
import CreditForm from './components/CreditForm';
import CreditBanners from './components/CreditBanners';

export default {
  name: 'App',
  components: {
    CreditForm,
    CreditBanners,
  },
};
</script>

<template lang="pug">
  .page
    .page__logo
      img(src="./assets/images/logotype.svg" alt="Займер")
    .page__form
      CreditForm
    .page__banners
      CreditBanners
</template>

<style lang="scss" scoped>
@import "assets/scss/base/mixins";

.page {
  position: relative;
  display: grid;
  gap: max(3vmin, 20px) 0;
  place-content: start center;
  padding: 50px 0;

  @include min(tablet-portrait) {

  }

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  &__logo {
    justify-self: center;
  }

  &__form {

  }

  &__banners {

  }
}
</style>
