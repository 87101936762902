interface ImageData {
  alt: string;
  width: number;
  height: number;
  loading: 'eager' | 'lazy';
  decoding: 'auto' | 'sync' | 'async';
  src: URL;
}

interface SourceSet {
  [key: number]: URL;
}

interface ImageSources {
  sizes: string;
  avif: SourceSet;
  webp: SourceSet;
  jpeg: SourceSet;
}

interface ImageObject {
  img: ImageData;
  source: ImageSources;
  background?: string;
}

interface ImagesData {
  wheel_sections: ImageObject;
  wheel_frame: ImageObject;
  page_bg: ImageObject;
  button_background: ImageObject;
  wheel_diamond: ImageObject;
}

const imagesData: ImagesData = {
  woman_01: {
    img: {
      alt: '',
      width: 900,
      height: 900,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/woman_01.png?as=jpeg&width=900', import.meta.url),
    },
    source: {
      sizes: '(max-width: 300px) 100vw, 300px',
      avif: {
        300: new URL('../assets/images/woman_01.png?as=avif&width=300', import.meta.url),
        600: new URL('../assets/images/woman_01.png?as=avif&width=600', import.meta.url),
        900: new URL('../assets/images/woman_01.png?as=avif&width=900', import.meta.url),
      },
      webp: {
        300: new URL('../assets/images/woman_01.png?as=webp&width=300', import.meta.url),
        600: new URL('../assets/images/woman_01.png?as=webp&width=600', import.meta.url),
        900: new URL('../assets/images/woman_01.png?as=webp&width=900', import.meta.url),
      },
      jpeg: {
        300: new URL('../assets/images/woman_01.png?as=jpeg&width=300', import.meta.url),
        600: new URL('../assets/images/woman_01.png?as=jpeg&width=600', import.meta.url),
        900: new URL('../assets/images/woman_01.png?as=jpeg&width=900', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  woman_02: {
    img: {
      alt: '',
      width: 900,
      height: 900,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/woman_02.png?as=jpeg&width=900', import.meta.url),
    },
    source: {
      sizes: '(max-width: 300px) 100vw, 300px',
      avif: {
        300: new URL('../assets/images/woman_02.png?as=avif&width=300', import.meta.url),
        600: new URL('../assets/images/woman_02.png?as=avif&width=600', import.meta.url),
        900: new URL('../assets/images/woman_02.png?as=avif&width=900', import.meta.url),
      },
      webp: {
        300: new URL('../assets/images/woman_02.png?as=webp&width=300', import.meta.url),
        600: new URL('../assets/images/woman_02.png?as=webp&width=600', import.meta.url),
        900: new URL('../assets/images/woman_02.png?as=webp&width=900', import.meta.url),
      },
      jpeg: {
        300: new URL('../assets/images/woman_02.png?as=jpeg&width=300', import.meta.url),
        600: new URL('../assets/images/woman_02.png?as=jpeg&width=600', import.meta.url),
        900: new URL('../assets/images/woman_02.png?as=jpeg&width=900', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
};

export default imagesData;
