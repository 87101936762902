class RangeSlider {
  constructor(sliderElement, valueElement) {
    this.rangeSlider = sliderElement;
    this.rangeValue = valueElement;
    this.maxValue = parseInt(this.rangeSlider.max, 10);
  }

  setActiveThumbColor(value) {
    let progress = (value / this.maxValue) * 100;

    progress = parseFloat(progress.toFixed(2));

    this.rangeSlider.style.background = `linear-gradient(to right, var(--track-fill) ${progress}%, var(--track-bg) ${progress}%)`;
  }

  static formatValue(value) {
    const formattedValue = Number(value).toLocaleString('ru-RU').replace(/,/g, ' ');

    return `${formattedValue}₽`;
  }

  updateValue(value) {
    this.rangeValue.value = RangeSlider.formatValue(value);
  }

  handleInput(event) {
    const { value } = event.target;

    this.setActiveThumbColor(value);
    this.updateValue(value);
  }

  init() {
    this.rangeSlider.addEventListener('input', (event) => this.handleInput(event));

    const initialValue = this.rangeSlider.value;

    this.setActiveThumbColor(initialValue);
    this.updateValue(initialValue);
  }
}

export default RangeSlider;
