<script>
import { ref, onMounted } from 'vue';
import { MaskInput } from 'maska';
import RangeSlider from '../utils/RangeSlider';
import FormValidator from '../utils/FormValidator';

export default {
  name: 'CreditForm',
  setup() {
    const refRangeValue = ref(null);
    const refRangeSlider = ref(null);
    const refPhoneInput = ref(null);
    const refCheckbox = ref(null);
    const refButton = ref(null);

    onMounted(async () => {
      const slider = new RangeSlider(refRangeSlider.value, refRangeValue.value);
      slider.init();

      await new MaskInput('[data-maska]');

      const validator = new FormValidator(refPhoneInput.value, refCheckbox.value, refButton.value);
      validator.init();
    });

    return {
      refRangeValue,
      refRangeSlider,
      refPhoneInput,
      refCheckbox,
      refButton,
    };
  },
};
</script>

<template lang="pug">
  .credit-form
    .credit-form__range
      .headline-large Укажите сумму займа

      input(type="text" name="amount" ref="refRangeValue" readonly)
      input(type="range" name="range" min="0" max="100000" value="25000" ref="refRangeSlider")

      .credit-form__range-markers
        span 5000₽
        span 50 000₽
        span 100 000₽

    .credit-form__phone
      .title-medium Введите номер телефона для поиска предложений

      input(type="text" name="phone" ref="refPhoneInput" data-maska="+7 (###) ###-##-##" placeholder="+7 (900) 123 45 67")

    .credit-form__rules
      .tickbox
        input#one(type="checkbox" ref="refCheckbox" checked)
        label(for="one")
          span Согласен с правилами сервиса

    .credit-form__button
      a.btn.inactive(ref="refButton" href="{offer}") Получить деньги
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.credit-form {
  --track-bg: var(--c-light-telegray);
  --track-fill: var(--c-blue);

  width: min(100%, 540px);
  margin: auto;
  padding: max(4vmin, 15px);
  border-radius: 3vmin;
  background-color: var(--c-white);
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;

  &__range {
    &-markers {
      display: grid;
      gap: 0;
      grid-template-columns: repeat(3, auto);
      justify-content: space-between;
      width: calc(100% - 32px);
      transform: translate(16px, -12px);
      color: var(--c-gray);
      font-size: 11px;
    }

    input[type="text"] {
      padding: 0 16px 3px;
      font-size: 2.4em;
    }
  }

  &__phone {
    input[type="text"] {
      --tf-c-placeholder: DarkGray;
      --tf-transition-easing: cubic-bezier(0, 0.202, 0.204, 1);

      padding: 0 16px;
      font-size: 16px;

      &::placeholder {
        color: var(--tf-c-placeholder);
        transition: opacity 225ms var(--tf-transition-easing);
      }

      &:focus::placeholder {
        opacity: 0.4;
      }

      &::placeholder {
        color: var(--tf-c-placeholder);
        transition: opacity 225ms var(--tf-transition-easing);
      }

      &:focus::placeholder {
        opacity: 0.4;
      }
    }
  }

  input[type="text"] {
    width: 100%;
    margin: 0;
    height: 64px;
    border: none;
    border-radius: 5.5px;
    background-color: var(--c-white);
    color: var(--c-almost-black);
    box-shadow: 0 0 0 1px inset var(--track-bg);

    &:focus {
      outline: none;
    }
  }

  input[type="range"] {
    appearance: none;
    margin: 10px 0;
    padding: 0;
    width: calc(100% - 30px);
    transform: translate(15px, -16px);
    cursor: pointer;
    outline: none;
    border-radius: 3.5px 3.5px 0 0;
    height: 6px;
    background-color: var(--track-bg);

    &::-webkit-slider-thumb {
      appearance: none;
      height: 20px;
      width: 20px;
      background-color: var(--c-white);
      box-shadow: 0 0 0 4px inset var(--track-fill);
      border-radius: 30px;
      border: none;
    }
  }

  .tickbox {
    --tickbox-label-font-color: var(--c-gray);
    --tickbox-color-active: var(--c-blue);

    input[type="checkbox"] {
      margin-right: 0 !important;
    }
  }

  .btn {
    letter-spacing: .5px;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    appearance: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    background-color: var(--c-blue);
    color: var(--c-white);
    border: none;
    border-radius: 5.5px;
    width: 100%;
    height: 64px;
    margin: 15px auto;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    display: grid;
    place-content: center;
    padding-top: 2px;
    transition: background-color 375ms ease-in-out;

    &.inactive {
      pointer-events: none;
      background-color: var(--c-light-telegray);
    }
  }
}
</style>
