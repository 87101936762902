<script>
import ImageContainer from './ImageContainer';

export default {
  name: 'CreditBanners',
  components: {
    ImageContainer,
  },
};
</script>

<template lang="pug">
  .credit-banners
    .credit-banners__item
      .credit-banners__item-title
        .title-medium Быстрая выдача
        .body-medium Деньги вы получите уже через 10 минут
      ImageContainer(file="woman_01", styleClass="woman-01")

    .credit-banners__item
      .credit-banners__item-title
        .title-medium Надежность
        .body-medium Все ваши данные надежно защищены
      ImageContainer(file="woman_02", styleClass="woman-02")
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.credit-banners {
  width: min(100%, 540px);
  margin: auto;
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  gap: 0 max(4vmin, 15px);

  &__item {
    display: grid;
    grid-template: 1fr auto / 1fr;
    gap: 5px 0;

    &-title {
      padding-left: 7px;
    }

    .image-container {
      overflow: hidden;
      border: 6px solid var(--c-white);
      border-radius: 5.5px;
    }
  }
}
</style>
