class FormValidator {
  constructor(phoneInput, checkbox, button) {
    this.phoneInput = phoneInput;
    this.checkbox = checkbox;
    this.button = button;
  }

  init() {
    this.phoneInput.addEventListener('input', () => this.validate());
    this.checkbox.addEventListener('change', () => this.validate());
    this.validate();
  }

  validate() {
    const phoneFilled = this.phoneInput.value.match(/\d/g)?.length === 11;
    const checkboxChecked = this.checkbox.checked;

    if (phoneFilled && checkboxChecked) {
      this.button.classList.remove('inactive');
    } else {
      this.button.classList.add('inactive');
    }
  }
}

export default FormValidator;
