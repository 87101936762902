<script>
import { computed } from 'vue';
import imagesData from '../data/imagesData';

export default {
  name: 'ImageContainer',
  props: {
    file: {
      type: String,
      required: true,
    },
    styleClass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const srcsetGenerator = (type) => {
      return computed(() => {
        if (imagesData[props.file]?.source?.[type]) {
          return Object.entries(imagesData[props.file].source[type])
            .map(([key, value]) => `${value} ${key}w`)
            .join(', ');
        }
        return '';
      });
    };

    const srcsetAvif = srcsetGenerator('avif');
    const srcsetWebp = srcsetGenerator('webp');
    const srcsetJpeg = srcsetGenerator('jpeg');

    const imageStyles = computed(() => (
      imagesData[props.file]?.background ? `background-image: url(${imagesData[props.file].background});` : ''
    ));

    return {
      srcsetAvif,
      srcsetWebp,
      srcsetJpeg,
      imageStyles,
      imagesData,
    };
  },
};
</script>

<template>
  <div :class="['image-container', styleClass ? `image-container--${styleClass}` : '']">
    <picture v-if="imagesData[file]" :style="imageStyles">
      <source
        v-if="imagesData[file]?.source?.avif"
        type="image/avif"
        :srcset="srcsetAvif"
        :sizes="imagesData[file].source.sizes"
      />
      <source
        v-if="imagesData[file]?.source?.webp"
        type="image/webp"
        :srcset="srcsetWebp"
        :sizes="imagesData[file].source.sizes"
      />
      <source
        v-if="imagesData[file]?.source?.jpeg"
        type="image/jpeg"
        :srcset="srcsetJpeg"
        :sizes="imagesData[file].source.sizes"
      />
      <img
        v-if="imagesData[file]?.img"
        :src="imagesData[file]?.img?.src"
        :width="imagesData[file]?.img?.width"
        :height="imagesData[file]?.img?.height"
        :loading="imagesData[file]?.img?.loading"
        :decoding="imagesData[file]?.img?.decoding"
        :alt="imagesData[file]?.img?.alt"
      />
    </picture>
  </div>
</template>

<style lang="scss" scoped>
.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
